<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !hideOrganization
      "
    >
      <organization-selector
        :organization="contact.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            contact.organization.id = organizationId;
            contact.contactable.id = null;
            onFormChanged();
          }
        "
        :disabled="!!contact.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="contact.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="contact.organization.id"
        @locationsChanged="
          (locations) => {
            contact.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      v-if="!hideContactable"
      :label="`${$t('CONTACTS.CONTACT_TYPE')} (*)`"
      :placeholder="$t('CONTACTS.CONTACT_TYPE')"
    >
      <el-select
        :name="`${$t('CONTACTS.CONTACT_TYPE')}`"
        :placeholder="$t('CONTACTS.CONTACT_TYPE')"
        v-model="contact.contactable.type"
        @onchange="
          () => {
            onFormChanged();
          }
        "
      >
        <el-option value="customers" :label="$t(`COMMON.CUSTOMER`)" />
        <el-option value="suppliers" :label="$t(`COMMON.SUPPLIER`)" />
        <el-option
          value="service-centers"
          :label="$t(`COMMON.SERVICECENTER`)"
        />
        <el-option value="establishments" :label="$t(`COMMON.ESTABLISHMENT`)" />
      </el-select>
    </base-input>

    <div v-if="contact.contactable.type">
      <!-- Supplier -->
      <div
        v-if="contact.contactable.type === 'suppliers' && !hideContactable"
        class="row"
      >
        <div class="col">
          <base-input
            :label="`${$t('COMMON.SUPPLIER')} (*)`"
            :placeholder="$t('COMMON.SUPPLIER')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
          >
            <supplier-selector
              :supplier="contact.contactable.id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="contact.organization.id"
              @supplierChanged="
                (supplierId) => {
                  contact.contactable.id = supplierId;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.contactable" />
        </div>
      </div>

      <!-- Customer -->
      <div
        v-if="contact.contactable.type === 'customers' && !hideContactable"
        class="row"
      >
        <div class="col">
          <base-input
            :label="`${$t('COMMON.CUSTOMER')} (*)`"
            :placeholder="$t('COMMON.CUSTOMER')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)"
          >
            <customer-selector
              :customer="contact.contactable.id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="contact.organization.id"
              @customerChanged="
                (customerId) => {
                  contact.contactable.id = customerId;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.contactable" />
        </div>
      </div>

      <!-- Customer -->
      <div
        v-if="
          contact.contactable.type === 'service-centers' && !hideContactable
        "
        class="row"
      >
        <div class="col">
          <base-input
            :label="`${$t('COMMON.SERVICECENTER')} (*)`"
            :placeholder="$t('COMMON.SERVICECENTER')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
          >
            <service-center-selector
              :serviceCenter="contact.contactable.id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="contact.organization.id"
              @serviceCenterChanged="
                (serviceCenterId) => {
                  contact.contactable.id = serviceCenterId;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.contactable" />
        </div>
      </div>

      <div
        v-if="contact.contactable.type === 'establishments' && !hideContactable"
        class="row"
      >
        <div class="col">
          <base-input
            :label="`${$t('COMMON.ESTABLISHMENT')} (*)`"
            :placeholder="$t('COMMON.ESTABLISHMENT')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
          >
            <establishment-selector
              :establishment="contact.contactable.id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="contact.organization.id"
              @establishmentChanged="
                (establishmentId) => {
                  contact.contactable.id = establishmentId;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.contactable" />
        </div>
      </div>

      <!-- Company name -->
      <div class="row">
        <div class="col">
          <base-input
            :label="`${$t('COMMON.COMPANY_NAME')}`"
            v-model="contact.company_name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.company_name" />
        </div>
      </div>

      <!-- Company name -->
      <div class="row">
        <div class="col">
          <base-input
            :label="`${$t('COMMON.POSITION')}`"
            v-model="contact.position"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.position" />
        </div>
      </div>

      <!-- Title - FirstName - LastName-->
      <div class="row">
        <!-- Title -->
        <div class="col-2">
          <base-input
            :label="`${$t('COMMON.TITLE')} (*)`"
            :placeholder="$t('COMMON.TITLE')"
            v-model="contact.title"
          >
            <el-select
              :name="`${$t('COMMON.TITLE')}`"
              v-model.lazy="contact.title"
              :placeholder="$t('COMMON.TITLE')"
              @onchange="
                () => {
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(label, val) in sourcesOption"
                :key="val"
                :value="val"
                :label="$t(`CONTACTS.TITLE_${label}`)"
              />
            </el-select>
            <validation-error :errors="apiValidationErrors.title" />
          </base-input>
          <validation-error :errors="apiValidationErrors.title" />
        </div>

        <!-- First name -->
        <div class="col">
          <base-input
            :label="`${$t('COMMON.FIRSTNAME')} (*)`"
            v-model="contact.firstname"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.firstname" />
        </div>

        <!-- Last name -->
        <div class="col">
          <base-input
            :label="`${$t('COMMON.LASTNAME')} (*)`"
            v-model="contact.lastname"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.lastname" />
        </div>
      </div>

      <!-- email address -->
      <base-input
        :label="`${$t('COMMON.EMAIL')}`"
        :type="'email'"
        v-model="contact.email"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.email" />

      <!-- Phone number -->
      <div class="row">
        <div class="col-2">
          <base-input :label="`${$t('COMMON.TYPE')}`">
            <el-select
              :label="$t('COMMON.TYPE')"
              :placeholder="$t('COMMON.TYPE')"
              v-model="contact.phone_type"
              @change="
                (type) => {
                  contact.phone_type = type;
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(value, key) in phoneTypesOptions"
                :key="key"
                :value="value"
                :label="$t(`COMMON.PHONE_TYPE_${value}`)"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-7">
          <base-input :label="`${$t('COMMON.PHONE')}`">
            <phone-number-input
              :phoneNumber="contact.phone"
              @phoneNumberChanged="
                (phone) => {
                  contact.phone = phone;
                  onFormChanged();
                }
              "
              :required="false"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.phone" />
        </div>
        <div class="col-3">
          <base-input
            :label="`${$t('COMMON.EXTENSION')}`"
            v-model="contact.phone_extension"
            @change="
              () => {
                onFormChanged();
              }
            "
            :inputClasses="'extension-input'"
          />
          <validation-error :errors="apiValidationErrors.phone_extension" />
        </div>
      </div>

      <!-- Other phone numbers -->
      <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
        <phone-numbers-selector
          :phoneNumbers="contact.other_phones"
          @phoneNumbersChanged="
            (phoneNumbers) => {
              contact.other_phones = phoneNumbers;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.other_phones" />

      <!-- Birth day -->
      <div class="row">
        <div class="col">
          <base-input :label="`${$t('COMMON.BIRTHDAY')}`">
            <flat-picker
              :config="{
                allowInput: true,
                locale: $flatPickrLocale(),
              }"
              class="form-control datepicker"
              v-model="contact.birthday"
              :placeholder="`${$t('COMMON.BIRTHDAY')}`"
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.birthday" />
        </div>
      </div>

      <div class="form-wrapper full mb-3">
        <base-input :label="`${$t('COMMON.ADDRESS')}`">
          <addresses-selector
            @addressInputChangeChanged="
              (values) => {
                contact = {
                  ...contact,
                  ...values,
                };
                onFormChanged();
              }
            "
          />
        </base-input>
      </div>
      <!-- Country - State - City - ZIP Code -->
      <div class="row">
        <div class="col">
          <base-input
            :label="`${$t('COMMON.COUNTRY')}`"
            :placeholder="$t('COMMON.COUNTRY')"
          >
            <country-selector
              :country="contact.country"
              :filterable="true"
              :showAll="false"
              @countryChanged="
                (country) => {
                  contact.country = country;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.country" />
        </div>

        <div class="col">
          <base-input
            :label="`${$t('COMMON.STATE')}`"
            :placeholder="$t('COMMON.STATE')"
          >
            <state-selector
              :country="contact.country"
              :state="contact.state"
              :filterable="true"
              :showAll="false"
              @stateChanged="
                (state) => {
                  contact.state = state;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.state" />
        </div>

        <div class="col">
          <base-input
            :label="`${$t('COMMON.CITY')}`"
            v-model="contact.city"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.city" />
        </div>

        <div class="col">
          <base-input
            :label="`${$t('COMMON.ZIPCODE')}`"
            v-model="contact.zipcode"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.zipcode" />
        </div>
      </div>

      <!-- Full Address -->
      <div class="row">
        <div class="col">
          <base-input
            :label="`${$t('COMMON.ADDRESS')}`"
            v-model="contact.address"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.address" />
        </div>
      </div>

      <!-- Tags -->
      <div class="row">
        <div class="col">
          <base-input
            :label="`${$t('COMMON.TAGS')}`"
            :placeholder="`${$t('COMMON.TAGS')}`"
          >
            <tags-selector
              :tags="contact.tags"
              @tagsChanged="
                (tags) => {
                  contact.tags = tags;
                  onFormChanged();
                }
              "
              :disabled="!contact.organization.id"
              :organization="contact.organization.id"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.tags" />
        </div>
      </div>

      <!-- Excerpt -->
      <div class="row">
        <div class="col">
          <base-input
            :label="$t('COMMON.EXCERPT')"
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <html-editor v-model="contact.excerpt" @change="onFormChanged()">
            </html-editor>
          </base-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </div>
      </div>

      <div class="my-4">
        <base-button
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading" />
          {{
            contact.id
              ? $t("CONTACTS.EDIT_CONTACT")
              : $t("CONTACTS.ADD_CONTACT")
          }}
        </base-button>
      </div>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { phoneTypesOptions } from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import { titleSourcesOption } from "@/constants/contacts";
import LocationsSelector from "@/components/LocationsSelector.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    CountrySelector,
    TagsSelector,
    StateSelector,
    OrganizationSelector,
    CustomerSelector,
    SupplierSelector,
    EstablishmentSelector,
    PhoneNumberInput,
    HtmlEditor,
    AddressesSelector,
    LocationsSelector,
    PhoneNumbersSelector,
    ServiceCenterSelector,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: [
    "contactData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideContactable",
    "hideSubmit",
  ],

  data() {
    let contactData = { ...this.contactData };
    contactData = this.$fillUserOrganizationData(contactData);
    return {
      contact: contactData,
      sourcesOption: titleSourcesOption,
      phoneTypesOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let contactData = cloneDeep(this.contact);
      contactData = this.$fillUserOrganizationData(contactData);
      this.$emit("contactSubmitted", contactData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    contactData(contactData) {
      if (contactData) {
        this.contact = {
          ...this.contact,
          ...cloneDeep(contactData),
        };
      }
    },
  },
};
</script>
