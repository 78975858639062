<template>
  <div class="container-fluid mt-5">
    <div>
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ serviceCenter.name }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_SERVICECENTERS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editServiceCenter()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_DELETE_SERVICECENTERS)"
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteServiceCenter()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button @click="goBack()" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div class="col-12 justify-content-center justify-content-sm-between flex-wrap">
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <service-center-view-global
                    :serviceCenter="serviceCenter"
                    @serviceCenterUpdated="get"
                  />
                </tab-pane>
                <tab-pane title="global" id="2">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.CONTACTS") }}
                  </span>
                  <service-center-view-contacts
                    :serviceCenter="serviceCenter"
                    @serviceCenterUpdated="get"
                  />
                </tab-pane>
                <tab-pane title="establishments" id="3">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.ESTABLISHMENTS") }}
                  </span>
                  <service-center-view-establishments
                    :serviceCenter="serviceCenter"
                    @serviceCenterUpdated="get"
                  />
                </tab-pane>

                <tab-pane
                  v-if="serviceCenter.has_warehouse"
                  title="establishment-products"
                  id="4"
                >
                  <span slot="title">
                    <i class="fa fa-cubes" />
                    {{ $t("COMMON.PRODUCTS") }}
                  </span>
                  <service-center-view-warehouse-products
                    :serviceCenter="serviceCenter"
                  />
                </tab-pane>

                <tab-pane
                  title="allowed-users"
                  id="5"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
                >
                  <span slot="title">
                    <i class="ni ni-single-02" />
                    {{ $t("COMMON.USERS") }}
                  </span>
                  <allowed-users-view
                    :object="serviceCenter"
                    :objectName="serviceCenter.name"
                    :objectStore="'serviceCenters'"
                    @objectUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="equipments" id="998">
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    Equipements
                  </span>
                  <service-center-view-equipments :serviceCenter="serviceCenter" />
                </tab-pane>
                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <service-center-view-logs :serviceCenter="serviceCenter" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Tabs, TabPane } from "@/components";
import AllowedUsersView from "@/components/AllowedUsers/AllowedUsersView.vue";
import defaultServiceCenter from "./defaultServiceCenter";
import ServiceCenterViewGlobal from "./partials/ServiceCenterViewGlobal.vue";
import ServiceCenterViewEstablishments from "./partials/ServiceCenterViewEstablishments.vue";
import ServiceCenterViewLogs from "./partials/ServiceCenterViewLogs.vue";
import ServiceCenterViewEquipments from "./partials/ServiceCenterViewEquipments.vue";
import ServiceCenterViewContacts from "./partials/ServiceCenterViewContacts.vue";
import ServiceCenterViewWarehouseProducts from "./partials/ServiceCenterViewWarehouseProducts.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ServiceCenterViewGlobal,
    ServiceCenterViewEstablishments,
    ServiceCenterViewLogs,
    AllowedUsersView,
    ServiceCenterViewContacts,
    ServiceCenterViewWarehouseProducts,
    ServiceCenterViewEquipments,
  },

  mixins: [],

  data() {
    return {
      serviceCenter: cloneDeep(defaultServiceCenter),
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("serviceCenters/get", id);
        this.serviceCenter = this.$store.getters["serviceCenters/serviceCenter"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editServiceCenter(row) {
      this.$router.push({
        name: "Edit ServiceCenter",
        params: { id: this.serviceCenter.id },
      });
    },

    async deleteServiceCenter() {
      const confirmation = await swal.fire({
        title: this.$t("SERVICECENTERS.DELETE_THIS_SERVICECENTER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("serviceCenters/destroy", this.serviceCenter.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("SERVICECENTERS.SERVICECENTER_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List ServiceCenters" });
    },
  },
};
</script>
